import React, {PropsWithChildren} from "react";
import {Navigate} from "react-router-dom";
import {useFirebase} from "./Firebase/context";
import * as ROUTES from "../constants/routes";

type ProtectedRouteProps = {
    requiresAdmin?: boolean
}

const ProtectedRoute = ({
                            requiresAdmin = false,
                            children
                        }: PropsWithChildren<ProtectedRouteProps>): JSX.Element => {
    const {isAdmin, isAuthenticated, isLoading} = useFirebase();

    // Don't do any validation until the user is loaded
    if (!isLoading) {
        // if the user is not authenticated, redirect to the sign in page
        if (!isAuthenticated) {
            return <Navigate to={ROUTES.SIGN_IN}/>;
        }

        // if the user is authenticated
        // and the user needs to be an admin
        // and the user is not an admin
        // redirect to the home screen
        if (requiresAdmin && !isAdmin) {
            return <Navigate to={ROUTES.HOME}/>;
        }
    }

    return <>{children}</>;
};

export default ProtectedRoute;