import {forwardRef, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {isEmpty, isUndefined} from 'lodash'

import {AlertProps, Box, Button, Paper, Snackbar, TextField, Typography} from '@mui/material'
import MuiAlert from '@mui/material/Alert'

import {useFirebase} from "../Firebase/context"
import * as ROUTES from '../../constants/routes'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PasswordForgetPage = () => (
    <Box my={3}
         mx={'auto'}
         sx={{justifyContent: 'center', maxWidth: 500}}>
        <Paper elevation={3}>
            <Box p={3}>
                <Typography variant="h1"
                            justifyContent='center'
                            display='flex'
                            align='center'>
                    Reset Password
                </Typography>
                <PasswordForgetForm/>
            </Box>
        </Paper>
    </Box>
)

const PasswordForgetForm = () => {
    const [email, setEmail] = useState<string>('')
    const [error, setError] = useState<string | null>(null)
    const [snackMessage, setSnackMessage] = useState<string | null>(null)
    const {resetPassword} = useFirebase()

    const onSubmit = (event: any) => {
        resetPassword(email)
            .then(() => {
                setEmail('')
                setSnackMessage("Please check your email for further instructions.")
            })
            .catch(error => {
                setError(error)
            });

        event.preventDefault()
    };

    const onChange = (event: any) => {
        setEmail(event.target.value)
    }

    const onSnackClose = () => {
        setSnackMessage(null)
    }

    const isInvalid = email === ''
    return (
        <Box
            m={3}
            gap={2}
            display={'flex'}
            flexDirection={'column'}>
            <TextField label={'Email'}
                       variant={'outlined'}
                       onChange={onChange}/>
            <Button variant={'contained'}
                    onClick={onSubmit}
                    disabled={isInvalid}>
                Reset Password
            </Button>
            {!isUndefined(error) && (
                <Typography
                    variant="body1"
                    color="error">
                    {error}
                </Typography>
            )}
            <Snackbar
                open={!isEmpty(snackMessage)}
                onClose={onSnackClose}
                autoHideDuration={3000}
                message={snackMessage}>
                <Alert onClose={onSnackClose}
                       severity={'success'}>
                    {snackMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

const PasswordForgetLink = () => {
    const navigate = useNavigate()
    const goToForgetPassword = () => {
        navigate(ROUTES.PASSWORD_FORGET)
    }

    return (
        <Button variant='text' onClick={goToForgetPassword}>Forgot Password?</Button>
    )
}

export default PasswordForgetPage;
export {PasswordForgetForm, PasswordForgetLink};
