import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const config = {
    apiKey: "AIzaSyACoeiD0m27UIK6n9YxBiSv41PtlZpK1Dg",
    authDomain: "wlbc-bulletin.firebaseapp.com",
    projectId: "wlbc-bulletin",
    storageBucket: "wlbc-bulletin.appspot.com",
    messagingSenderId: "342763011887",
    appId: "1:342763011887:web:18ddb3f24486d88eb86bda",
    measurementId: "G-2HY6PN1ZFJ",
    databaseURL: "https://wlbc-bulletin.firebaseio.com",
};

const app = initializeApp(config);
export const auth = getAuth(app);
export const firestore = getFirestore(app)
export default app;

// class Firebase {
//   constructor() {
//     app.initializeApp(config);
//
//     this.auth = app.auth();
//     this.db = app.firestore();
//   }
//
//   // *** Auth API ***
//   doCreateUserWithEmailAndPassword = (email, password) =>
//     this.auth.createUserWithEmailAndPassword(email, password);
//
//   doSignInWithEmailAndPassword = (email, password) =>
//     this.auth.signInWithEmailAndPassword(email, password);
//
//   doSignOut = () => this.auth.signOut();
//
//   doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
//
//   doPasswordUpdate = password =>
//     this.auth.currentUser.updatePassword(password);
//
//   // *** Merge Auth and DB User API *** //
//   onAuthUserListener = (next, fallback) =>
//     this.auth.onAuthStateChanged(authUser => {
//       if (authUser) {
//         this.user(authUser.uid)
//           .get()
//           .then(function(doc) {
//             const dbUser = doc.data();
//
//             // default empty roles
//             if (!dbUser.roles) {
//               dbUser.roles = [];
//             }
//
//             // merge auth and db user
//             authUser = {
//               ...dbUser,
//               uid: authUser.uid
//             };
//
//             next(authUser);
//           });
//       } else {
//         fallback();
//       }
//     });
//
//   // *** User API ***
//   user = uid => this.db.collection(`users`).doc(uid);
//
//   users = () => this.db.collection('users');
//
//   // *** Public Service API ***
//   welcomeMessage = sunday => this.db.collection('welcomeMessage').where("date", "==", sunday);
//
//   orderOfService = sunday => this.db.collection('orderOfService').where("date", "==", sunday).orderBy("order");
//
//   prayerRequests = sunday => this.db.collection('prayerRequests').where("date", "==", sunday);
//
//   announcements = sunday => this.db.collection('announcements').where("date", "==", sunday);
//
//   // *** CRUD API ***
//   async saveFirestoreObject(collectionName, object) {
//     const docRef = await this.db.collection(collectionName).add(object)
//     console.log(collectionName + " written with ID: ", docRef.id);
//   }
// }
//
// export default Firebase;
