import React from 'react';
import {useNavigate} from "react-router-dom"
import {useFirebase} from "../Firebase/context";
import {AppBar, Button, Container, Stack, Toolbar, Typography} from "@mui/material";
import * as ROUTES from "../../constants/routes";
import SignOutButton from "../SignOut";

const Navigation = () => {
    const {isAuthenticated, isAdmin} = useFirebase()

    return (
        <div>
            {isAuthenticated ? <NavigationAuth isAdmin={isAdmin}/> : <NavigationNonAuth/>
            }
        </div>
    );
}

type NavigationAuthProps = {
    isAdmin: boolean
}

const NavigationAuth = ({isAdmin}: NavigationAuthProps) => {
    const navigate = useNavigate()

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Woodlyn Baptist Church
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{flexGrow: 1, justifyContent: 'flex-end'}}>
                        <Button
                            color={'inherit'}
                            sx={{my: 2, display: 'block'}}
                            onClick={() => navigate(ROUTES.ACCOUNT)}
                        >
                            Account
                        </Button>
                        {isAdmin && (
                            <Button
                                color={'inherit'}
                                sx={{my: 2, display: 'block'}}
                                onClick={() => navigate(ROUTES.ADMIN)}
                            >
                                Admin
                            </Button>
                        )}
                        <SignOutButton/>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

const NavigationNonAuth = () => {
    const navigate = useNavigate()

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Woodlyn Baptist Church
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{flexGrow: 1, justifyContent: 'flex-end'}}>
                        <Button
                            color={'inherit'}
                            sx={{my: 2, display: 'block'}}
                            onClick={() => navigate(ROUTES.SIGN_IN)}
                        >
                            Sign In
                        </Button>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navigation;
