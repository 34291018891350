import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useFirebase} from '../Firebase/context'
import * as ROUTES from '../../constants/routes'
import {Box, Button, Link, Paper, TextField, Typography} from "@mui/material"
import {SignInLink} from "../SignIn";
import {isEmpty} from "lodash";

const SignUpPage = () => (
    <Box my={3}
         mx={'auto'}
         sx={{justifyContent: 'center', maxWidth: 500}}>
        <Paper elevation={3}>
            <Box p={3}>
                <Typography variant="h1"
                            justifyContent='center'
                            display='flex'>
                    Sign Up
                </Typography>
                <Box
                    display={'flex'}
                    sx={{justifyContent: 'center'}}>
                    <SignUpForm/>
                </Box>
            </Box>
        </Paper>
    </Box>
)

const SignUpForm = () => {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [passwordOne, setPasswordOne] = useState('')
    const [passwordTwo, setPasswordTwo] = useState('')
    const [error, setError] = useState<any | null>(null)
    const [userCreated, setUserCreated] = useState(false)
    const navigate = useNavigate()
    const {signup} = useFirebase()

    const onSubmit = (event: any) => {
        signup(email, passwordOne, username)
            .then(() => {
                setUsername('');
                setEmail('');
                setPasswordOne('');
                setPasswordTwo('');
                setUserCreated(true)
            })
            .catch(error => {
                setError(error);
            });

        event.preventDefault();
    }

    useEffect(() => {
        if (userCreated) {
            navigate(ROUTES.ACCOUNT)
        }
    }, [userCreated])

    const isInvalid = passwordOne !== passwordTwo ||
        passwordOne === '' ||
        email === '' ||
        username === ''
    return (
        <Box m={3}
             gap={2}
             display='flex'
             flexDirection='column'>
            <TextField label='Full Name'
                       variant='outlined'
                       onChange={event => setUsername(event.target.value)}
                       fullWidth/>
            <TextField label='Email Address'
                       variant='outlined'
                       onChange={event => setEmail(event.target.value)}
                       fullWidth/>
            <TextField label='Password'
                       variant='outlined'
                       type='password'
                       onChange={event => setPasswordOne(event.target.value)}
                       fullWidth/>
            <TextField label='Confirm Password'
                       variant='outlined'
                       type='password'
                       onChange={event => setPasswordTwo(event.target.value)}
                       fullWidth/>
            <Button variant='contained'
                    onClick={onSubmit}
                    disabled={isInvalid}>
                Sign Up
            </Button>
            {!isEmpty(error) && (
                <Typography variant='body1'
                            color='error'>
                    {error.message}
                </Typography>
            )}
            <SignInLink/>
        </Box>
    )
}

export const SignUpLink = () => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate(ROUTES.SIGN_UP)
    }

    return (
        <Typography variant='body1'>
            Don't have an account?
            <Button variant='text' component={Link} onClick={onClick}>
                Sign Up
            </Button>
        </Typography>
    )
}

export default SignUpPage;
