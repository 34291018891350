import {useRef, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {isEmpty} from 'lodash'

import {Box, Button, Link, Paper, TextField, Typography} from "@mui/material"

import {SignUpLink} from '../SignUp'
import {PasswordForgetLink} from '../PasswordForget'
import {useFirebase} from '../Firebase/context'
import * as ROUTES from '../../constants/routes'

const SignInPage = () => (
    <Box my={3}
         mx={'auto'}
         sx={{justifyContent: 'center', maxWidth: 500}}>
        <Paper elevation={3}>
            <Box p={3}>
                <Typography variant="h1"
                            justifyContent='center'
                            display='flex'>
                    Sign In
                </Typography>
                <SignInForm/>
                <Box
                    display={'flex'}
                    sx={{justifyContent: 'center'}}>
                    <SignUpLink/>
                </Box>
            </Box>
        </Paper>
    </Box>
);


const translateError = (error: any) => {
    if (error.code === 'auth/user-not-found') {
        return {
            message: 'User not found',
            isUsername: true,
        }
    } else if (error.code === 'auth/wrong-password') {
        return {
            message: 'Invalid password',
            isPassword: true,
        }
    } else {
        return {
            message: 'Unable to sign in'
        }
    }
}

const SignInForm = () => {
    const email = useRef<string>()
    const password = useRef<string>()
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null)
    const [userCreated, setUserCreated] = useState<boolean>(false);
    const {login} = useFirebase();

    const onSubmit = (event: any) => {
        // clear errors
        setUsernameError(null)
        setPasswordError(null)
        setError(null)

        login(email.current ?? '', password.current ?? '')
            .then(() => {
                setUserCreated(true);
            })
            .catch(error => {
                const errorObject = translateError(error)
                if (errorObject.isUsername) {
                    setUsernameError(errorObject.message)
                } else if (errorObject.isPassword) {
                    setPasswordError(errorObject.message)
                } else {
                    setError(errorObject.message)
                }
            });

        event.preventDefault();
    };

    const isInvalid = password.current === '' || email.current === '';
    if (userCreated) {
        return <Navigate to={ROUTES.HOME}/>
    }

    return (
        <Box
            m={3}
            gap={2}
            display={'flex'}
            flexDirection={'column'}>
            <TextField label='Email'
                       variant='outlined'
                       error={!isEmpty(usernameError)}
                       helperText={!isEmpty(usernameError) ? usernameError : ''}
                       fullWidth
                       onChange={event => {
                           setUsernameError(null)
                           email.current = event.target.value ?? ''
                       }}/>
            <TextField label='Password'
                       variant='outlined'
                       type='password'
                       error={!isEmpty(passwordError)}
                       helperText={!isEmpty(passwordError) ? passwordError : ''}
                       fullWidth
                       onChange={event => {
                           setPasswordError(null)
                           password.current = event.target.value ?? ''
                       }}/>
            <Box display='flex'
                 sx={{justifyContent: 'end'}}>
                <PasswordForgetLink/>
            </Box>
            <Button
                onClick={onSubmit}
                variant="contained"
                disabled={isInvalid}>
                Sign In
            </Button>
            {!isEmpty(error) && (
                <Typography
                    variant="body1"
                    color="error">
                    {error}
                </Typography>
            )}
        </Box>
    );
}

export const SignInLink = () => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate(ROUTES.SIGN_IN)
    }

    return (
        <Typography variant='body1'>
            Already have an account?
            <Button variant='text' component={Link} onClick={onClick}>
                Sign In
            </Button>
        </Typography>
    )
}

export default SignInPage;
