import React from 'react'

import {Box, Typography} from '@mui/material'

import {PasswordForgetForm} from '../PasswordForget'
import PasswordChangeForm from '../PasswordChange'
import {useFirebase} from "../Firebase/context"

const AccountPage = () => {
    const {user} = useFirebase()
    console.log("user account", user)

    return (
        <Box>


            <Typography variant='h1'>
                Welcome{user === undefined || user.displayName === undefined ? '' : `, ${user.displayName}`}
            </Typography>
            <PasswordForgetForm/>
            <PasswordChangeForm/>
        </Box>
    )
}

export default AccountPage
