import React, {useEffect, useState} from 'react';

import {useFirebase} from "../Firebase/context";
import * as ROUTES from '../../constants/routes';
import {useNavigate} from "react-router-dom";

const AdminPage = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers] = useState([]);
    const {fetchUsers} = useFirebase();
    const navigate = useNavigate();

    const routeChange = () => {
        navigate(ROUTES.ADD_BULLETIN)
    }

    useEffect(() => {
        setLoadingUsers(true)

        fetchUsers()
            .then(userList => {
                setUsers(userList);
                setLoadingUsers(false);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    return (
        <div>
            <h1>Admin</h1>
            <p>
                The Admin Page is accessible by every signed in admin user.
            </p>

            {loadingUsers && <div>Loading ...</div>}

            <UserList users={users}/>

            <button onClick={routeChange}>Add Bulletin</button>
        </div>
    );
}

const UserList = ({users}) => (
    <ul>
        {users.map(user => (
            <li key={user.uid}>
        <span>
          <strong>ID:</strong> {user.uid}
        </span>
                <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
                <span>
          <strong>Username:</strong> {user.name}
        </span>
            </li>
        ))}
    </ul>
);

export default AdminPage;
