import React from 'react';
import '../../../index.css';
import {Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import {BasicOOSItem} from "../../../types/BasicOOSItem";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {noop} from "lodash";

type OOSItemProps = {
    item: BasicOOSItem
    canEdit?: boolean
    deleteAction?: (item: BasicOOSItem) => void
}
const OOSItem = (props: OOSItemProps) => {
    return (
        <Card>
            <CardHeader
                title={props.item.name}
                subheader={props.item.speaker}
                action={
                    props.canEdit && (
                        <IconButton onClick={() => {
                            const deleteAction = props.deleteAction ? props.deleteAction : noop
                            deleteAction(props.item)
                        }}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    )
                }
            >
            </CardHeader>
            {!!props.item.description && (
                <CardContent>
                    <Typography variant={"body2"}>
                        {props.item.description}
                    </Typography>
                </CardContent>
            )}
        </Card>
    )
}

export default OOSItem
