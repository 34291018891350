import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import {addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, where,} from "firebase/firestore";
import {auth, firestore} from "./firebase";

interface FirebaseContextType {
    isLoading: boolean,
    isAuthenticated: boolean,
    isAdmin: boolean,
    user: any,
    signup: (email: string, password: string, username: string) => Promise<void>,
    login: (email: string, password: string) => Promise<void>,
    logout: () => Promise<void>,
    resetPassword: (email: string) => Promise<void>,
    updatePassword: (password: string) => Promise<void>,
    fetchOrderOfService: (sunday: string) => Promise<any>,
    fetchAnnouncements: (sunday: string) => Promise<any>,
    fetchUsers: () => Promise<any>,
    saveFirestoreObject: (collectionName: string, object: any) => Promise<void>
}

const firebaseContext = createContext<FirebaseContextType | null>(null)

type FirebaseContextProviderProps = {
    children: ReactNode
}

export function FirebaseContextProvider({children}: FirebaseContextProviderProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [user, setUser] = useState<any | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const signup = async (email: string, password: string, username: string) => {
        return await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setDoc(doc(firestore, "users", userCredential.user.uid), {
                    email: email,
                    name: username,
                    roles: [],
                })
            })
            .then(() => {
                setIsLoading(true)
                console.log("User created")
            })
    }

    const login = async (email: string, password: string) => {
        return await signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setIsLoading(true)
            })
    }

    const logout = () => {
        return signOut(auth);
    }

    const resetPassword = (email: string) => {
        return sendPasswordResetEmail(auth, email,)
    }

    const updatePassword = (password: string) => {
        // @ts-ignore
        return auth.currentUser.updatePassword(password);
    }

    const fetchOrderOfService = async (sunday: string) => {
        const oosQuery = query(
            collection(firestore, "orderOfService"),
            where("date", "==", sunday),
            orderBy("order")
        );

        const querySnapshot = await getDocs(oosQuery);
        const oosItems: any[] = []
        querySnapshot.forEach((doc) => {
            const oos = {
                ...doc.data(),
                "oosId": doc.id
            }

            oosItems.push(oos)
        })

        return oosItems
    }

    const fetchAnnouncements = async (sunday: string) => {
        const q = query(
            collection(firestore, "announcements"),
            where("date", "==", sunday)
        );

        const snapshot = await getDocs(q);
        const announcements: any[] = []
        snapshot.forEach((doc) => {
            const announcement = {
                ...doc.data(),
                "aId": doc.id
            }

            announcements.push(announcement)
        })

        return announcements
    }

    const fetchUsers = async () => {
        const usersSnapshot = await getDocs(collection(firestore, "users"))
        let users: any[] = []
        usersSnapshot.forEach((doc) => {
            const user = {
                ...doc.data(),
                "uid": doc.id
            }

            users.push(user)
        })

        return users
    }

    const fetchUser = async (uid: string) => {
        const docRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data()
        }
    }

    const saveFirestoreObject = async (collectionName: string, object: any) => {
        const docRef = await addDoc(collection(firestore, collectionName), object)
        console.log(collectionName + " written with ID: ", docRef.id)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, userObj => {
            if (userObj) {
                fetchUser(userObj.uid)
                    .then(dbUser => {
                        const newUser: any = userObj

                        const isAdmin = dbUser === undefined ? false : dbUser.roles.includes("ADMIN")
                        newUser.displayName = dbUser === undefined ? '' : dbUser.name
                        newUser.isAdmin = isAdmin

                        setUser(newUser);
                        setIsAuthenticated(true)
                        setIsAdmin(isAdmin)
                        setIsLoading(false);
                    })
            } else {
                setUser(undefined);
                setIsAuthenticated(false)
                setIsAdmin(false)
                setIsLoading(false);
            }

        });

        return () => {
            unsubscribe();
        }
    }, []);

    const firebaseCtx: FirebaseContextType = {
        isLoading,
        isAuthenticated,
        isAdmin,
        user,
        signup,
        login,
        logout,
        resetPassword,
        updatePassword,
        fetchOrderOfService,
        fetchAnnouncements,
        fetchUsers,
        saveFirestoreObject
    }
    return (
        <firebaseContext.Provider value={firebaseCtx}>
            {children}
        </firebaseContext.Provider>
    )
}

export const useFirebase = () => {
    const ctx = useContext(firebaseContext);

    if (!ctx) {
        throw new Error(
            "useFirebase has to be used within <FirebaseContextProvider>"
        );
    }

    return ctx;
}