import React, {useEffect, useState} from 'react';

import {useFirebase} from "../Firebase/context";
import {Box, Stack, Typography} from "@mui/material";
import OOSItem from "../ui/OOSItem";
import AnnouncementItem from "../ui/AnnouncementItem";

const Home = () => {
    const [loadingOos, setLoadingOos] = useState(false);
    const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);
    const [orderOfService, setOrderOfService] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const {
        fetchOrderOfService,
        fetchAnnouncements,
    } = useFirebase();

    let newDate = new Date();
    let distance = 0 - newDate.getDay();
    newDate.setDate(newDate.getDate() + distance);

    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let dateString = `${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}-${year}`
    let displayDateString = `${month < 10 ? `${month}` : `${month}`}/${date < 10 ? `${date}` : `${date}`}/${year}`

    useEffect(() => {
        setLoadingOos(true);
        setLoadingAnnouncements(true);


        fetchOrderOfService(dateString)
            .then(oosItems => {
                setOrderOfService(oosItems);
                setLoadingOos(false);
            })

        fetchAnnouncements(dateString)
            .then(announcementItems => {
                setAnnouncements(announcementItems);
                setLoadingAnnouncements(false);
            })
    }, [])

    return (
        <Box m={5}>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent='center'>
                    <Typography variant="h1">
                        Bulletin for {displayDateString}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography variant="h2">
                        Order Of Service
                    </Typography>
                    {loadingOos && <div>Loading Order Of Service ...</div>}
                    {orderOfService.length !== 0 && (
                        <Stack gap={2}>
                            {orderOfService.map((item, index) => (
                                <OOSItem key={index}
                                         item={item}/>
                            ))}
                        </Stack>
                    )}
                </Stack>
                <Stack>
                    <Typography variant="h2">
                        Announcements
                    </Typography>
                    {loadingAnnouncements && <div>Loading Announcements ...</div>}
                    {announcements.length !== 0 && (
                        <Stack gap={2}>
                            {announcements.map((item, index) => (
                                <AnnouncementItem announcement={item} key={index}/>
                            ))}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
}

export default Home
