import React from 'react';
import '../../../index.css';
import {Card, CardContent, CardHeader, IconButton, Stack, Typography} from "@mui/material";
import {BasicAnnouncementItem} from "../../../types/BasicAnnouncementItem";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {noop} from "lodash";

type AnnouncementItemProps = {
    announcement: BasicAnnouncementItem
    canEdit?: boolean
    deleteAction?: (item: BasicAnnouncementItem) => void
}
const AnnouncementItem = ({announcement, canEdit, deleteAction}: AnnouncementItemProps) => {
    const lines = (announcement.description ? announcement.description : "").split(/\r?\\n/);

    return (
        <Card>
            <CardHeader
                title={announcement.title}
                action={
                    canEdit && (
                        <IconButton onClick={() => {
                            const action = deleteAction ? deleteAction : noop
                            action(announcement)
                        }}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    )
                }/>
            {lines.length !== 0 && (
                <CardContent>
                    <Stack>
                        {lines.map((item, index) => (
                            <Typography m={2} variant="body2" key={index}>{item}</Typography>
                        ))}
                    </Stack>
                </CardContent>
            )}
        </Card>
    )
}

export default AnnouncementItem
