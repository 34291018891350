import React, {useRef, useState} from 'react';

import {useFirebase} from "../Firebase/context";
import OOSItem from '../ui/OOSItem'
import AnnouncementItem from '../ui/AnnouncementItem'

import {Box, Button, IconButton, Modal, Paper, Stack, TextField, Typography} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {BasicOOSItem} from "../../types/BasicOOSItem";
import {BasicAnnouncementItem} from "../../types/BasicAnnouncementItem";

const AddBulletin = () => {
    const [oosModalOpen, setOosModalOpen] = useState(false)
    const [announcementModalOpen, setAnnouncementModalOpen] = useState(false)
    const [value, setValue] = useState(new Date());
    const [oosItems, setOosItems] = useState<BasicOOSItem[]>([]);
    const [announcements, setAnnouncements] = useState<BasicAnnouncementItem[]>([]);
    const {saveFirestoreObject} = useFirebase();

    const nameValue = useRef("")
    const descriptionValue = useRef("")
    const speakerValue = useRef("")
    const titleValue = useRef("")
    const announcementValue = useRef("")

    const closeModal = () => {
        nameValue.current = ""
        descriptionValue.current = ""
        speakerValue.current = ""
        titleValue.current = ""
        announcementValue.current = ""

        setOosModalOpen(false)
        setAnnouncementModalOpen(false)
    }

    const addOOSItem = () => {
        let newItems = oosItems
        newItems.push({
            name: nameValue.current,
            description: descriptionValue.current,
            speaker: speakerValue.current,
        })

        setOosItems(newItems)
        closeModal()
    }

    const addAnnouncement = () => {
        let newItems = announcements
        newItems.push({
            title: titleValue.current,
            description: announcementValue.current,
        })

        setAnnouncements(newItems)
        closeModal()
    }

    const month = value.getMonth() + 1
    const day = value.getDate()
    const year = value.getFullYear()
    const dateStr = `${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}-${year}`
    const pickerDateStr = `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`

    const saveBulletin = () => {
        let order = 1
        oosItems.forEach(item => {
            saveFirestoreObject("orderOfService", {
                date: dateStr,
                description: item.description,
                name: item.name,
                speaker: item.speaker,
                order: order
            })
            order++
        })

        order = 1
        announcements.forEach(item => {
            saveFirestoreObject("announcements", {
                date: dateStr,
                description: item.description,
                title: item.title,
                order: order
            })
            order++
        })

        setValue(new Date())
        setOosItems([])
        setAnnouncements([])
    }

    const removeOOSItem = (item: BasicOOSItem) => {
        setOosItems(oosItems.filter(i => i !== item))
    }

    const removeAnnouncement = (item: BasicAnnouncementItem) => {
        setAnnouncements(announcements.filter(i => i !== item))
    }

    return (
        <Box m={5}>
            <Stack direction='row' justifyContent='center'>
                <Typography variant="h1">Add Bulletin</Typography>
            </Stack>
            <Box my={3}
                 mx={'auto'}
                 gap={2}
                 sx={{justifyContent: 'center', maxWidth: '75%'}}>
                <Stack mt={2}
                       direction='row'
                       alignItems='center'
                       justifyContent='space-between'>
                    <Stack>
                        <Typography variant="h4">Date</Typography>
                        <Typography variant="caption">
                            What is the date of the service?
                        </Typography>
                    </Stack>
                    <TextField
                        id="date"
                        label="Date"
                        type="date"
                        defaultValue={pickerDateStr}
                        sx={{width: 220}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            let now = new Date(e.target.value)
                            let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
                            setValue(utc)
                        }}
                    />
                </Stack>
                <Stack mt={3}>
                    <Stack direction='row'
                           alignItems='center'
                           justifyContent='space-between'>
                        <Typography variant="h4">Order Of Service</Typography>
                        <IconButton color="primary"
                                    onClick={() => setOosModalOpen(true)}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Stack>
                    {oosItems.length !== 0 && (
                        <Stack gap={2}>
                            {oosItems.map((item, index) => (
                                <OOSItem key={index}
                                         item={item}
                                         canEdit={true}
                                         deleteAction={removeOOSItem}/>
                            ))}
                        </Stack>
                    )}
                </Stack>
                <Stack mt={3}>
                    <Stack direction='row'
                           alignItems='center'
                           justifyContent='space-between'>
                        <Typography variant="h4">Announcement</Typography>
                        <IconButton color="primary"
                                    onClick={() => setAnnouncementModalOpen(true)}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Stack>
                    {announcements.length !== 0 && (
                        <Stack gap={2}>
                            {announcements.map((item, index) => (
                                <AnnouncementItem key={index}
                                                  announcement={item}
                                                  canEdit={true}
                                                  deleteAction={removeAnnouncement}/>
                            ))}
                        </Stack>
                    )}
                </Stack>
                <Stack mt={3}
                       direction='row'
                       alignItems='center'
                       justifyContent='center'>
                    <Button variant="contained"
                            color="primary"
                            onClick={() => saveBulletin()}
                            sx={{width: '25%'}}>
                        Save
                    </Button>
                </Stack>
            </Box>
            <Modal open={oosModalOpen}>
                <Box my={3}
                     mx={'auto'}
                     sx={{justifyContent: 'center', maxWidth: '60%', outline: 'none'}}>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <Stack gap={3}>
                                <Typography variant="h3"
                                            justifyContent='center'
                                            display='flex'>
                                    Order Of Service Item
                                </Typography>
                                <TextField label="Name"
                                           variant="outlined"
                                           onChange={e => nameValue.current = e.target.value}
                                           required/>
                                <TextField label="Description"
                                           variant="outlined"
                                           onChange={e => descriptionValue.current = e.target.value}/>
                                <TextField label="Speaker"
                                           variant="outlined"
                                           onChange={e => speakerValue.current = e.target.value}/>
                                <Button variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addOOSItem()
                                        }}>
                                    Add
                                </Button>
                                <Button variant='outlined'
                                        color='primary'
                                        onClick={() => {
                                            closeModal()
                                        }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
            </Modal>
            <Modal open={announcementModalOpen}>
                <Box my={3}
                     mx={'auto'}
                     sx={{justifyContent: 'center', maxWidth: '60%', outline: 'none'}}>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <Stack gap={3}>
                                <Typography variant="h3"
                                            justifyContent='center'
                                            display='flex'>
                                    Announcement
                                </Typography>
                                <TextField label="Title"
                                           variant="outlined"
                                           onChange={e => titleValue.current = e.target.value}
                                           required/>
                                <TextField label="Description"
                                           variant="outlined"
                                           onChange={e => announcementValue.current = e.target.value}/>
                                <Button variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addAnnouncement()
                                        }}>
                                    Add
                                </Button>
                                <Button variant='outlined'
                                        color='primary'
                                        onClick={() => {
                                            closeModal()
                                        }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddBulletin;
