import {Outlet} from 'react-router'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Navigation from '../Navigation'
import HomePage from '../Home'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import AccountPage from '../Account'
import AdminPage from '../Admin'
import AddBulletin from '../AddBulletin'

import * as ROUTES from '../../constants/routes'
import {FirebaseContextProvider} from "../Firebase/context"
import ProtectedRoute from "../ProtectedRoute"
import {createTheme, ThemeProvider} from "@mui/material"

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#42a5f4',
            light: '#80d6ff',
            dark: '#0077c1',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cddc39',
            light: '#ffff6e',
            dark: '#99aa00',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#4caf50',
            contrastText: '#fff',
        },
        text: {
            primary: '#212121',
            secondary: '#757575',
            disabled: '#9e9e9e',
        }
    }
})

const WithNavigation = () => (
    <>
        <Navigation/>
        <Outlet/>
    </>
)
const WithoutNavigation = () => <Outlet/>

const App = () => (
    <Router>
        <div>
            <ThemeProvider theme={theme}>
                <FirebaseContextProvider>
                    <Routes>
                        <Route element={<WithNavigation/>}>
                            <Route path={ROUTES.HOME} element={<HomePage/>}/>
                        </Route>
                        <Route element={<WithoutNavigation/>}>
                            <Route path={ROUTES.SIGN_UP} element={<SignUpPage/>}/>
                        </Route>
                        <Route element={<WithoutNavigation/>}>
                            <Route path={ROUTES.SIGN_IN} element={<SignInPage/>}/>
                        </Route>
                        <Route element={<WithoutNavigation/>}>
                            <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage/>}/>
                        </Route>
                        <Route element={<WithNavigation/>}>
                            <Route path={ROUTES.ACCOUNT} element={
                                <ProtectedRoute>
                                    <AccountPage/>
                                </ProtectedRoute>
                            }/>
                        </Route>
                        <Route element={<WithNavigation/>}>
                            <Route path={ROUTES.ADMIN} element={
                                <ProtectedRoute requiresAdmin>
                                    <AdminPage/>
                                </ProtectedRoute>
                            }/>
                        </Route>
                        <Route element={<WithNavigation/>}>
                            <Route path={ROUTES.ADD_BULLETIN} element={
                                <ProtectedRoute requiresAdmin>
                                    <AddBulletin/>
                                </ProtectedRoute>
                            }/>
                        </Route>
                    </Routes>
                </FirebaseContextProvider>
            </ThemeProvider>
        </div>
    </Router>
)

export default App
