import React, {useState} from 'react';

import {useFirebase} from "../Firebase/context";

const PasswordChangeForm = () => {
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [error, setError] = useState(null);
    const {updatePassword} = useFirebase();

    const onSubmit = event => {
        updatePassword(passwordOne)
            .then(() => {
                setPasswordOne('');
                setPasswordTwo('');
                setError(null);
            })
            .catch(error => {
                setError(error);
            });

        event.preventDefault();
    };

    const onChangePasswordOne = event => {
        setPasswordOne(event.target.value);
    }

    const onChangePasswordTwo = event => {
        setPasswordTwo(event.target.value);
    }

    const isInvalid =
        passwordOne !== passwordTwo || passwordOne === '';

    return (
        <form onSubmit={onSubmit}>
            <input
                name="passwordOne"
                value={passwordOne}
                onChange={onChangePasswordOne}
                type="password"
                placeholder="New Password"
            />
            <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={onChangePasswordTwo}
                type="password"
                placeholder="Confirm New Password"
            />
            <button disabled={isInvalid} type="submit">
                Reset My Password
            </button>

            {error && <p>{error.message}</p>}
        </form>
    );
}

export default PasswordChangeForm
