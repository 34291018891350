import React from 'react';
import {Navigate} from 'react-router-dom';
import {useFirebase} from "../Firebase/context";

import * as ROUTES from '../../constants/routes';
import {Button} from "@mui/material";

const SignOutButton = () => {
    const {logout} = useFirebase();
    const [didLogout, setDidLogout] = React.useState(false);

    const doLogout = () => {
        setDidLogout(true);
        logout();
    }

    if (didLogout) {
        return <Navigate to={ROUTES.HOME}/>
    }

    return (
        <Button
            color={'inherit'}
            sx={{my: 2, display: 'block'}}
            onClick={doLogout}
        >
            Sign Out
        </Button>
    )
}

export default SignOutButton;
